// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// local dependencies
import Section from './Section';
import TextBlock from './TextBlock';
import Image from './Image';
import EnhancedGrid from './EnhancedGrid';

const StyledEnhancedGrid = styled((props) => <EnhancedGrid {...props} />)`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

// component definition
const TwoColNews = ({
  image,
  superText,
  headline,
  body,
  buttonText,
  buttonLink,
  fileUrl,
  reverse,
}) => {
  return (
    <Section>
      <StyledEnhancedGrid
        reverse={reverse}
        container
        wrap='wrap'
        justifyContent='space-evenly'
        align='flex-start'
        spacing={5}
      >
        <Grid item xs={12} sm={12} md={6}>
          <TextBlock
            superText={superText}
            headline={headline}
            body={body}
            buttonText={buttonText}
            buttonLink={buttonLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Image shortImage image={image} />
        </Grid>
      </StyledEnhancedGrid>
    </Section>
  );
};

TwoColNews.propTypes = {
  image: PropTypes.shape({}).isRequired,
  superText: PropTypes.string,
  headline: PropTypes.string,
  body: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  reverse: PropTypes.bool,
};

TwoColNews.defaultProps = {
  superText: '',
  headline: '',
  body: '',
  buttonText: '',
  buttonLink: '',
  reverse: false,
};

export default TwoColNews;
