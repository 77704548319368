// external Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSection = styled.div`
  position: relative;
  padding: ${(props) => (props.dense ? '0px 50px' : '75px 50px')};
  transform: ${(props) =>
    props.shiftUp
      ? 'translateY(-25%)'
      : props.shiftDown
      ? 'translateY(-25%)'
      : 'translateY(0px)'};
  /* background-color: ${(props) => props.theme.colors.white}; */
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
  margin: auto;
  max-width: 1200px;

  h2,
  h3,
  h4 {
    max-width: 600px;
    margin: auto;
    color: ${(props) => props.theme.colors.blue};
  }

  @media (max-width: 960px) {
    transform: translateY(0px);
    padding: 50px 25px;
  }
`;

// component definition
const Section = ({
  whiteBkg,
  ecruBkg,
  children,
  dense,
  shiftUp,
  shiftDown,
  textCenter,
  ...other
}) => {
  return (
    <StyledSection
      whiteBkg={whiteBkg}
      ecruBkg={ecruBkg}
      dense={dense}
      shiftUp={shiftUp}
      shiftDown={shiftDown}
      textCenter={textCenter}
      {...other}
    >
      {children}
    </StyledSection>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  dense: PropTypes.bool,
  shiftUp: PropTypes.bool,
  shiftDown: PropTypes.bool,
  whiteBkg: PropTypes.string,
  ecruBkg: PropTypes.string,
};

Section.defaultProps = {
  dense: false,
  shiftUp: false,
  shiftDown: false,
  whiteBkg: '',
  ecruBkg: '',
};

export default Section;
