// external dependencies
import React from 'react';
import styled from 'styled-components';

// gatsby dependencies
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledImg = styled(GatsbyImage)`
  min-width: 500px;
  max-width: 650px;
  border-radius: ${(props) => props.theme.elements.borderRadius};
  box-shadow: ${(props) => props.theme.effects.boxShadow};

  @media (max-width: 1200px) {
    min-width: 400px;
  }

  @media (max-width: 1100px) {
    min-width: 375px;
  }

  @media (max-width: 1024px) {
    min-width: 350px;
  }

  @media (max-width: 900px) {
    min-width: unset;
    width: 85vw;
    margin-bottom: 25px;
  }
`;

// component definition
const Image = ({ image, shortImage, altText, ...other }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "fence-placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <StyledImg
      objectFit='contain'
      shortImage={shortImage}
      {...other}
      image={image ?image.gatsbyImageData : data.placeholderImage.childImageSharp.gatsbyImageData}
      alt={altText || ''}
    />
  );
};

export default Image;
