// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// local dependencies
import Button from './Button';
import SuperText from './SuperText';

const StyledButtonWrapper = styled.div`
  display: ${(props) => (props.extraButton ? 'flex' : 'block')};

  button {
    @media (min-width: 600px) {
      margin-right: 16px;
    }

    @media (max-width: 599px) {
      margin: 0 8px;
    }
  }
`;

const StyledWrapper = styled.div`
  display: block;
  text-align: left;
  min-width: 450px;
  max-width: 500px;
  margin: auto;

  h3 {
    margin: 0;
    color: ${(props) => props.theme.colors.blue};
  }

  p {
    color: ${(props) => props.theme.colors.darkGray};
    margin: 1.5rem auto 2rem auto;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: ${(props) => props.theme.colors.gold};
    }
  }

  @media (max-width: 1024px) {
    min-width: 400px;
    max-width: 400px;
  }

  @media (max-width: 900px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
    text-align: center;
    padding-bottom: 2rem;
    margin: auto;

    h3 {
      margin: auto;
    }
    ${StyledButtonWrapper} {
      justify-content: center;
    }
  }
`;

// component definition
const TextBlock = ({
  superText,
  headline,
  body,
  buttonText,
  buttonLink,
  downloadLink,
  extraButton,
}) => {
  return (
    <StyledWrapper>
      {superText && <SuperText title={superText} />}
      <h3>{headline}</h3>
      <p>{body}</p>
      <StyledButtonWrapper extraButton={extraButton}>
        {buttonText && (
          <Button icon href={downloadLink ? downloadLink : `${buttonLink}/`}>
            {buttonText}
          </Button>
        )}
        {extraButton && (
          <Button icon href='https://concretefence.concora.com/products'>
            See Our Design Center
          </Button>
        )}
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

TextBlock.propTypes = {
  superText: PropTypes.string,
  headline: PropTypes.string,
  body: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  downloadLink: PropTypes.string,
  extraButton: PropTypes.bool,
};

TextBlock.defaultProps = {
  headline: '',
  superText: '',
  buttonText: '',
  buttonLink: '',
  downloadLink: '',
  body: '',
  extraButton: false,
};

export default TextBlock;
