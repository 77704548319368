import React from 'react';
import Hero from '../components/shared/Hero';
import { useStaticQuery, graphql } from 'gatsby';
import TwoColNews from '../components/shared/TwoColNews';

const ThankYouPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulNewsPage {
        page
        newsModules {
          title
          supertext
          buttonText
          reverseOrder
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          file {
            file {
              url
            }
          }
          bodyCopy {
            bodyCopy
          }
        }
      }
    }
  `);

  const renderNews = data.page.newsModules.map((item, i) => {
    return (
      <TwoColNews
        key={i}
        image={item.image}
        superText={item.supertext}
        headline={item.title}
        body={item.bodyCopy.bodyCopy}
        buttonText={item.buttonText}
        fileUrl={item.file.file.url}
        reverse={item.reverseOrder}
      />
    );
  });

  return (
    <>
      <Hero
        superText='Thanks for reaching out'
        headline='We will be in touch soon'
        body='In the mean time, feel free to check out our Trade Newsletters!'
        short
      />
      {renderNews}
    </>
  );
};

export default ThankYouPage;
